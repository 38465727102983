export const BG_PRIMARY = '#757c3c';
export const BG_SECONDARY = '#F1F2DE';
export const BG_ALTERNATIVE = '#E8ECB5';

export const TEXT_PRIMARY = '#fff';
export const TEXT_SECONDARY = '#352023';
export const TEXT_PRIMARY_LIGHT = '#352023';

export const BTN_NAVIGATION_COLOR = '#a9a993';

// Opacity layer for left content (Desktop view)
export const BG_SECONDARY_TRANSPARENT = '';

export const HEADING_STYLES = {
  color: 'mainColorText',
  fontFamily: 'heading',
  fontSize: '5xl',
  letterSpacing: '1px',
  textAlign: 'center',
  textTransform: 'capitalize',
  //paddingLeft: '28px',
};

export const LINK_STYLES = {
  color: 'bgAlternative',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontStyle: 'italic',
};

export const DEFAULT_BUTTON_PROPS = {};
export const BUTTON_PROPS = {};
